import React from 'react'
import Avatar from '@material-ui/core/Avatar'

const defaultSize = 25

const AvatarField = ({ picture, size }) => (
  <Avatar
    src={picture}
    size={size}
    style={{width: size, height: size}}
  />
)
AvatarField.defaultProps = {
  size: defaultSize
}

const UserAvatarField = ({size, record}) => {
  const picture = record.picture ? record.picture
    : (record.main_user ? record.main_user.picture : null)
  return (
    picture ? (<AvatarField picture={picture} size={size ? size : defaultSize} />) : null
  )
}

export { AvatarField, UserAvatarField }
