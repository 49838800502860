import React from 'react'
import _ from 'lodash'

import { TextField } from 'react-admin'

const ApiSummaryField = ({ record, filter }) => {
  const items = filter ? _.filter(record.feeds, filter) : record.feeds
  // Remove duplicate api_kind entries
  const unique_apis = items.filter((f1, index, arr) => (
    arr.findIndex((f2) => f2.api_kind === f1.api_kind) === index
  ))
  return (
    <div>
      { unique_apis.length > 0 ? unique_apis.map((item, i) => (
        <TextField key={i} record={item} source='api_kind' />))
        : (<div> — </div>)
      }
    </div>
  )
}

ApiSummaryField.defaultProps = { addLabel: true, label: 'APIs' }

export { ApiSummaryField }
