import React from 'react'

export const Footer = (props) => {
  const TAG_VERSION = process.env.REACT_APP_VERSION
  return (
    <footer className='app-footer'>
      <span>© {new Date().getFullYear()}</span>
      { 
        // <a href='/terms.html' target='_blank'>AGB</a> 
        // <a href='/privacy.html' target='_blank'>Datenschutzerklärung</a>
      }
      <a href='/imprint.html' target='_blank'>Impressum</a>
      <span>{TAG_VERSION || 'v0.5.x'}</span>
    </footer>
  )
}
