import React from 'react'
import {
  BooleanField,
  CardActions,
  CheckboxGroupInput,
  DateField,
  ExportButton,
  RefreshButton,
  SaveButton,
  SelectInput,
  Toolbar } from 'react-admin'

import { getUserScopes } from 'au_processor'
import { Auth0Context } from './authentication/Auth0Provider'
import AddAufschaltungButton from './custom_components/AddAufschaltungButton'

export const AufschaltungTitle = ({ record }) => {
  let title = (record && record.main_user && record.main_user.email) || (record && record.nickname) || ''
  title = title ? `«${title}»` : ''
  return <span>Aufschaltung {title}</span>
}

export const SelectStage = (props) => {
  const { label, source, value } = props
  return (
    <SelectInput label={label} source={source} defaultValue={value}
      choices={[
        { id: 'PROD', name: 'PROD' },
        { id: 'PREVIEW', name: 'PREVIEW' },
        { id: 'DEMO', name: 'DEMO' },
        { id: 'COLD', name: 'COLD' },
        { id: 'INTERN', name: 'INTERN' },
        { id: 'SYSTEM', name: 'SYSTEM' }
      ]}
    />
  )
}

export const CheckStage = (props) => {
  const { label, source } = props
  return (
    <CheckboxGroupInput label={label} source={source}
      choices={[
        { id: 'PROD', name: 'PROD' },
        { id: 'PREVIEW', name: 'PREVIEW' },
        { id: 'DEMO', name: 'DEMO' },
        { id: 'COLD', name: 'COLD' },
        { id: 'INTERN', name: 'INTERN' },
        { id: 'SYSTEM', name: 'SYSTEM' }
      ]}
    />
  )
}

export const AufschaltungEditToolbar = props => (
  <Toolbar {...props} >
      <SaveButton />
      {/*<CancelButton />*/}
  </Toolbar>
)

export const AufschaltungActions = ({
  bulkActions,
  basePath,
  currentSort,
  displayedFilters,
  exporter,
  filters,
  filterValues,
  onUnselectItems,
  resource,
  selectedIds,
  showFilter,
  total
}) => {
  return (
    <Auth0Context.Consumer>
    {auth0_context => {
      const scopes = getUserScopes(auth0_context.access_token)
      return (
        <CardActions>
          {bulkActions && React.cloneElement(bulkActions, {
              basePath,
              filterValues,
              resource,
              selectedIds,
              onUnselectItems,
          })}
          {filters && React.cloneElement(filters, {
              resource,
              showFilter,
              displayedFilters,
              filterValues,
              context: 'button',
          }) }

          {
            scopes.includes('add:aufschaltung')
            ? <AddAufschaltungButton /> : null
          }
          <ExportButton
            disabled={total === 0}
            resource={resource}
            sort={currentSort}
            filter={filterValues}
            exporter={exporter} />
          <RefreshButton />
        </CardActions>
      )
    }}
    </Auth0Context.Consumer>
  )
}

export const AufschaltungSendToSapField = ({ record }) => {
  const { send_to_sap } = record
  return (
    <div>
      <BooleanField record={{ send_to_sap: send_to_sap ? true : false }}
        source='send_to_sap' />
    </div>
  )
}

export const AufschaltungCreated = ({ record }) => {
  const { created } = record
  return (
    <div>
      {
        created ?
        (<DateField record={record} source='created' locales='de' />) :
        ' — '
      }
    </div>
  )
}