import React from 'react'
import {
  BooleanInput,
  Edit,
  LongTextInput,
  SimpleForm,
  TextInput
} from 'react-admin'

import { AufschaltungTitle, SelectStage } from './Aufschaltung'
import { EditToolbar } from './custom_components/CustomReactAdminComponent'

const redirect = (basePath, id, data) => `/Aufschaltung/${data.id}/show/3`

export const AufschaltungEdit = props => (
    <Edit title={<AufschaltungTitle />} {...props}>
      <SimpleForm toolbar={<EditToolbar />} redirect={redirect}>
        <SelectStage label='Status' source='stage' />
        <TextInput label='Organisation' source='orga_name' />
        <TextInput label='SAP-Kunde' source='orga_number' />
        <BooleanInput label="Übertragung ins SAP-System" source="send_to_sap" />
        <LongTextInput label='Kommentar' source='orga_description' />
      </SimpleForm>
    </Edit>
)
