import React, { Component, Fragment } from 'react'
import { Admin, Resource } from 'react-admin'
import { Router } from 'react-router-dom'
import { createBrowserHistory } from 'history'

import ApolloClient from 'apollo-client'
import { createHttpLink } from 'apollo-link-http'
import { setContext } from 'apollo-link-context'
import { InMemoryCache } from 'apollo-cache-inmemory'

import buildGraphQLProvider from 'ra-data-graphql-simple'
import germanMessages from 'ra-language-german'

import ContactMail from '@material-ui/icons/ContactMail'
import FormatListBulleted from '@material-ui/icons/FormatListBulleted'

import { AUTH_LOGIN, AUTH_LOGOUT, /*AUTH_ERROR,*/ AUTH_CHECK } from 'react-admin'
import './App.css'

import { Auth0Provider, Auth0Context } from './components/authentication/Auth0Provider.js'
import { AtokenProvider } from './components/authentication/AtokenProvider.js'

import { AufschaltungCreate } from './components/AufschaltungCreate'
import { AufschaltungEdit } from './components/AufschaltungEdit'
import { AufschaltungList } from './components/AufschaltungList'
import { AufschaltungShow } from './components/AufschaltungShow'
import { Footer } from './components/Footer'
import { InvitationCreate } from './components/InvitationCreate'
import { InvitationEdit } from './components/InvitationEdit'
import { InvitationList } from './components/InvitationList'
import { InvitationShow } from './components/InvitationShow'
import { Loading } from './components/custom_components/Loading'

const history = createBrowserHistory()
const messages = {
  'de': germanMessages
}
const i18nProvider = locale => messages[locale]

const aTokenStore = {atoken: null}

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_APP_SYNC_GRAPHQL_ENDPOINT
})

const authLink = setContext((_, { headers }) => {
  const atoken = aTokenStore.atoken
  return {
    headers: {
      ...headers,
      authorization: atoken || ''
    }
  }
})

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache({addTypename: false})
})

class ReactAdminApp extends Component {
  constructor () {
    super()
    this.state = { dataProvider: null }
  }

  ensureDataProvider () {
    buildGraphQLProvider({
      client: client
    }).then(dataProvider => this.setState({ dataProvider }),
            error => console.log('###', error))
  }

  makeAuthProvider () {
    const { auth0_context } = this.props

    const authProvider = (type, params) => {
      if (type === AUTH_LOGIN || type === AUTH_CHECK) {
        return auth0_context.signed_in ? Promise.resolve() : Promise.reject()
      }
      if (type === AUTH_LOGOUT) {
        setTimeout(() => auth0_context.logout(), 10)
        return Promise.resolve()
      }
      return Promise.resolve('Unknown method')
    }
    return authProvider
  }

  render () {
    const { aTokenStore } = this.props
    const { dataProvider } = this.state
    const atoken = aTokenStore && aTokenStore.atoken

    if (atoken && !dataProvider) {
      setTimeout(() => this.ensureDataProvider(), 100)
    }

    if (!atoken) {
      return <Loading />
    }
    if (!dataProvider) {
      return <Loading />
    }

    const LoginPage = () => (<Loading />)

    return (
      <Fragment>
        <Admin
          //dashboard={Dashboard}
          loginPage={LoginPage}
          dataProvider={dataProvider}
          authProvider={this.makeAuthProvider()}
          history={history}
          locale='de'
          //messages={messages}
          i18nProvider={i18nProvider}
        >
          <Resource
            name='Aufschaltung'
            options={{ label: 'Aufschaltungen' }}
            icon={FormatListBulleted}
            list={AufschaltungList}
            show={AufschaltungShow}
            create={AufschaltungCreate}
            edit={AufschaltungEdit}
          />
          <Resource
            name='Invitation'
            options={{ label: 'Einladungen' }}
            icon={ContactMail}
            list={InvitationList}
            show={InvitationShow}
            create={InvitationCreate}
            edit={InvitationEdit} />
          {/*<Resource name='User' options={{ label: 'User' }} list={UserList} />*/}
          {/*<Resource name='Feed' />*/}
          {/*<Resource name='Profile' />*/}
        </Admin>
        <Footer />
      </Fragment>

    )
  }
}

const App = () => (
  <Router history={history}>
    <Auth0Provider home_path='/' title='API-Desk'>
      <Auth0Context.Consumer>
        {auth0_context => (
          <AtokenProvider {...{...auth0_context, redirect_path: '/', store: aTokenStore}} >

            <ReactAdminApp auth0_context={auth0_context} aTokenStore={aTokenStore} />

          </AtokenProvider>
        ) }
      </Auth0Context.Consumer>
    </Auth0Provider>
  </Router>
)

export default App
