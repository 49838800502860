import React from 'react'
import {
    Create,
    DateInput,
    NumberInput,
    TextInput,
    SimpleForm } from 'react-admin'

import { EditToolbar } from './custom_components/CustomReactAdminComponent'

const redirect = (basePath, id, data) => `/Invitation/${data.id}/show`

export const InvitationCreate = (props) => {
  let expires_at = new Date()
  expires_at.setDate(expires_at.getDate()+30)
  // Format only date
  expires_at = expires_at.toISOString().split('T')[0]

  return (
    <Create {...props}>
      <SimpleForm toolbar={<EditToolbar />} redirect={redirect}>
        {/* TODO: show list of Aufschaltungen
            <SelectInput
              label='Aufschaltungen source='Aufschaltung'
              choices={[
                { id: 'auid1', name: 'auid1' },
                { id: 'auid2', name: 'auid2' }
                ]}
              />
        */}
        <TextInput source='aufschaltung_id' label='Aufschaltung' />
        <TextInput source='orga_name' label='Organisation' />
        <TextInput source='orga_contact_person' label='Kontaktperson' />
        <TextInput source='email' label='Email' />
        <NumberInput source='max_user_nb' label='Max. Anzahl der User' defaultValue={5} />
        <DateInput source='expires_at' label='Gültig bis' defaultValue={expires_at} locales='de' />
      </SimpleForm>
    </Create>
  )
}
