import React from 'react'
import {
  Edit,
  SimpleForm,
  TextInput
} from 'react-admin'

import { InvitationTitle } from './Invitation'
import { EditToolbar } from './custom_components/CustomReactAdminComponent'

const redirect = (basePath, id, data) => `/Invitation/${data.id}/show`

export const InvitationEdit = props => {
  return (
    <Edit title={<InvitationTitle />} {...props}>
      <SimpleForm toolbar={<EditToolbar />} redirect={redirect}>
        <TextInput source='orga_name' label='Organisation' />
        <TextInput source='orga_contact_person' label='Kontaktperson' />
        <TextInput source='email' label='Email' />
      </SimpleForm>
    </Edit>
  )
}