import React from 'react'
import { Link } from 'react-router-dom'

import { Button } from 'react-admin'

import AddIcon from '@material-ui/icons/Add'
import { withStyles } from '@material-ui/core/styles'

import { buttonStyles } from './styles'

const AddAufschaltungButton = (/*{ classes, record }*/) => {
  return (
    <Button
      //className={classes.button}
      variant='flat'
      component={Link}
      to={`/Aufschaltung/create`}
      label='Aufschaltung hinzufügen'
      title='Aufschaltung hinzufügen'
    >
      <AddIcon />
    </Button>
  )
}

export default withStyles(buttonStyles)(AddAufschaltungButton)
