import React, { Component } from 'react'
import PropTypes from 'prop-types'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import AddToPhotosIcon from '@material-ui/icons/AddToPhotos'
import ContentCreate from '@material-ui/icons/Create'
import { withStyles } from '@material-ui/core/styles'
import Menu from '@material-ui/core/Menu'
import MenuItem from '@material-ui/core/MenuItem'
import ListItemIcon from '@material-ui/core/ListItemIcon'
import ListItemText from '@material-ui/core/ListItemText'
import {
  Button,
  GET_ONE,
  UPDATE,
  withDataProvider, } from 'react-admin'

import { Auth0Context } from '../authentication/Auth0Provider'
import { defaultProfileMapper, openProfileEditor } from 'au_processor'
import { buttonStyles } from './styles'

const StyledMenu = withStyles({
  paper: {
    border: '1px solid #d3d4d5',
  },
})((props) => (
  <Menu
    elevation={0}
    getContentAnchorEl={null}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    {...props}
  />
))

class ProfileEditorButton extends Component {
  state = {
    anchorEl: null
  }

  showCreateMenu = (e) => {
    this.setState({ anchorEl: e.currentTarget })
  }

  handleClick = (e, auth0_context, aufschaltung, id, defaultProfile, onProfileEditFinished) => {
    const profile = defaultProfile ? defaultProfileMapper(defaultProfile) : null
    openProfileEditor(e, auth0_context, aufschaltung, id, profile, onProfileEditFinished)
    this.handleClose()
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  onProfileEditFinished = ({complete_au, partial_au}) => {
    const {
      dataProvider,
      onProfileEditFinished,
      onDisableButtons } = this.props

    const updatedRecord = { ...partial_au };
    const auid = partial_au.auid
    // Notify parent component
    onProfileEditFinished({complete_au, processUpdatingAufschaltung: true})

    dataProvider(UPDATE, 'Aufschaltung', { id: auid, data: updatedRecord }, {
      undoable: true,
      onSuccess: {
        notification: { body: 'Profil wird aktualisiert', level: 'info' },
        callback: () => {
          dataProvider(GET_ONE, 'Aufschaltung', { id: auid }, {
            onSuccess: {
              notification: { body: 'Profilaktualisierung abgeschlossen', level: 'info' },
              callback: () => {
                onDisableButtons(false)
              }
            },
            onFailure: {
              notification: { body: 'Fehler bei Aktualisierung des Profils', level: 'warning' },
              callback: () => {
                onDisableButtons(false)
              }
            }
          })
        }
      },
      onFailure: {
        notification: { body: 'Fehler bei Aktualisierung des Profils', level: 'warning' },
        callback: () => {
          onDisableButtons(false)
        }
      }
    })
  }

  render () {
    const {
        aufschaltung,
        classes,
        isCreate,
        record,
        buttonsDisabled } = this.props
    const { anchorEl } = this.state
    const { id } = record
    const label = isCreate ? 'Profil hinzufügen' : 'Profil bearbeiten'

    return (
      <Auth0Context.Consumer>
        {auth0_context => (
          <div>
            <Button
              className={classes.button}
              variant='flat'
              onClick={
                isCreate ? this.showCreateMenu :
              (e) => this.handleClick(
                e, auth0_context, aufschaltung, id, null, this.onProfileEditFinished)}
              target='_blank'
              label={label}
              title={label}
              disabled={buttonsDisabled}
            >
              { isCreate ? <AddToPhotosIcon /> : <ContentCreate /> }
            </Button>
            { isCreate ?
              <StyledMenu
                id="customized-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={this.handleClose}
              >
                <MenuItem onClick={(e) => this.handleClick(
                  e, auth0_context, aufschaltung, id, 'WIRES', this.onProfileEditFinished)}>
                  <ListItemIcon>
                    <AddCircleOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="wires" />
                </MenuItem>
                <MenuItem onClick={(e) => this.handleClick(
                  e, auth0_context, aufschaltung, id, 'WEBLINES', this.onProfileEditFinished)}>
                  <ListItemIcon>
                    <AddCircleOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="dpa-Weblines" />
                </MenuItem>
                <MenuItem onClick={(e) => this.handleClick(
                  e, auth0_context, aufschaltung, id, 'INFOLINE_RS', this.onProfileEditFinished)}>
                  <ListItemIcon>
                    <AddCircleOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="dpa-Infoline_rs" />
                </MenuItem>
                <MenuItem onClick={(e) => this.handleClick(
                  e, auth0_context, aufschaltung, id, 'BASISDIENST_BILDFUNK', this.onProfileEditFinished)}>
                  <ListItemIcon>
                    <AddCircleOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="dpa-Basisdienst/Bildfunk" />
                </MenuItem>
                <MenuItem onClick={(e) => this.handleClick(
                  e, auth0_context, aufschaltung, id, 'BASISPAKET', this.onProfileEditFinished)}>
                  <ListItemIcon>
                    <AddCircleOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="dpa-Basispaket" />
                </MenuItem>
                <MenuItem onClick={(e) => this.handleClick(
                  e, auth0_context, aufschaltung, id, 'NEWSSCREEN', this.onProfileEditFinished)}>
                  <ListItemIcon>
                    <AddCircleOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="dpa-Newsscreen" />
                </MenuItem>
                <MenuItem onClick={(e) => this.handleClick(
                  e, auth0_context, aufschaltung, id, 'RADIOLINE', this.onProfileEditFinished)}>
                  <ListItemIcon>
                    <AddCircleOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="dpa-Radioline" />
                </MenuItem>
                <MenuItem onClick={(e) => this.handleClick(
                  e, auth0_context, aufschaltung, id, 'THEMENWELTEN', this.onProfileEditFinished)}>
                  <ListItemIcon>
                    <AddCircleOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="dpa-Themenwelten" />
                </MenuItem>
                <MenuItem onClick={(e) => this.handleClick(
                  e, auth0_context, aufschaltung, id, 'TRENDS', this.onProfileEditFinished)}>
                  <ListItemIcon>
                    <AddCircleOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="dpa-Trends" />
                </MenuItem>
                <MenuItem onClick={(e) => this.handleClick(
                  e, auth0_context, aufschaltung, id, 'INTERNATIONAL', this.onProfileEditFinished)}>
                  <ListItemIcon>
                    <AddCircleOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="dpa-International" />
                </MenuItem>
                <MenuItem onClick={(e) => this.handleClick(
                  e, auth0_context, aufschaltung, id, 'INTERNACIONAL', this.onProfileEditFinished)}>
                  <ListItemIcon>
                    <AddCircleOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="dpa-Internacional" />
                </MenuItem>
                <MenuItem onClick={(e) => this.handleClick(
                  e, auth0_context, aufschaltung, id, 'INTERNATIONAL_ARABIC', this.onProfileEditFinished)}>
                  <ListItemIcon>
                    <AddCircleOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="dpa-Arabic" />
                </MenuItem>
                <MenuItem onClick={(e) => this.handleClick(
                  e, auth0_context, aufschaltung, id, 'INTERNATIONAL_PHOTO', this.onProfileEditFinished)}>
                  <ListItemIcon>
                    <AddCircleOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="dpa-international-photo" />
                </MenuItem>
                <MenuItem onClick={(e) => this.handleClick(
                  e, auth0_context, aufschaltung, id, 'KNA', this.onProfileEditFinished)}>
                  <ListItemIcon>
                    <AddCircleOutlineIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText primary="kna" />
                </MenuItem>
              </StyledMenu>
             : null}
          </div>
        )}
      </Auth0Context.Consumer>
    )
  }
}

ProfileEditorButton.propTypes = {
  dataProvider: PropTypes.func.isRequired,
  record: PropTypes.object
}

export default withDataProvider(withStyles(buttonStyles)(ProfileEditorButton))
