import React from 'react'
import {
  BooleanField,
  DateField,
  NumberField,
  TextField } from 'react-admin'

const IsMainUserField = ({ aufschaltung, record, ...props }) => {
  const mainUserId = aufschaltung.main_user ? aufschaltung.main_user.id : ''
  const isMainUser = mainUserId === record.id
  return (
    <BooleanField {...props} record={{isMainUser}} source='isMainUser' />
  )
}
IsMainUserField.defaultProps = { addLabel: true }

const MainUserSummaryField = ({ record }) => {
  const { main_user, users } = record
  const entries = main_user ? [{email: main_user.email + ' (*)'}] : users && users.length ? users : []
  return (
    <div>
      { entries.length > 0 ? entries.map((user, i) => (
        <TextField key={i} record={user} source='email' />))
        : (<div> — </div>)
      }
    </div>
  )
}
MainUserSummaryField.defaultProps = { addLabel: true }

const MainUserLastLogin = ({ record, sortOrder }) => {
  const { main_user, users } = record

  const maxLastLogin = users && users.reduce((u1, u2) => {
    if (u1 === '') {
      return u2
    }
    return {
      last_login: u1.last_login > u2.last_login ?
                  u1.last_login : u2.last_login
      }
  }, '')
  return (
    <div>
    { main_user
      ? (<DateField record={main_user} source='last_login' locales='de' />)
      : users && !users.length ? ' — '
        : (<DateField record={maxLastLogin} source='last_login' locales='de' />)
    }
    </div>
  )
}
MainUserLastLogin.defaultProps = { addLabel: true }

const MainUserLoginsCount = ({ record }) => {
  const { main_user, users } = record

  let totalLoginsCount = users && users.reduce((u1, u2) => {
      return { logins_count: u1.logins_count + u2.logins_count }
      }, { logins_count: 0 })
  return (
    <div>
    { main_user
      ? (<NumberField record={main_user} source='logins_count' />)
      : users && !users.length ? ' — '
        : (<NumberField record={totalLoginsCount} source='logins_count' />)
    }
    </div>
  )
}
MainUserLoginsCount.defaultProps = { addLabel: true }

const MainUserCreatedAt = ({ record, sortOrder }) => {
  const { main_user, users } = record

  const maxCreatedAt = users && users.reduce((u1, u2) => {
    if (u1 === '') {
      return u2
    }
    return {
      created_at: u1.created_at > u2.created_at ?
                  u1.created_at : u2.created_at
      }
  }, '')
  return (
    <div>
    { main_user
      ? (<DateField record={main_user} source='created_at' locales='de'/>)
      : users && !users.length ? ' — '
        : (<DateField record={maxCreatedAt} source='created_at' locales='de' />)
    }
    </div>
  )
}
MainUserCreatedAt.defaultProps = { addLabel: true }

export {
  IsMainUserField,
  MainUserSummaryField,
  MainUserLastLogin,
  MainUserLoginsCount,
  MainUserCreatedAt
}
