import React, { Component } from 'react'
import {
  ArrayField,
  BooleanField,
  Datagrid,
  TextField,
  SimpleShowLayout } from 'react-admin'

import { withStyles } from '@material-ui/core/styles'

import startsWith from 'lodash/startsWith'

import { Auth0Context } from './authentication/Auth0Provider'
import ProfileEditorButton from './custom_components/ProfileEditorButton'
import MaterialEditField from './custom_components/MaterialEditField'

import { getUserScopes } from 'au_processor'

const OredTagRegexpsField = ({ record }) => {
  const { query_ored_tag_regexps } = record
  return (
    <div>
      {
        query_ored_tag_regexps.map((exp, i) => {
          let tagr = ''
          if (startsWith(exp, 'dnlpath:')) {
            tagr = 'rubrik:' + exp.replace('dnlpath:','')
          } else if (startsWith(exp, 'dpasrv:')) {
            tagr = 'wire:' + exp.replace('dpasrv:','')
          } else if (startsWith(exp, 'nasrv:')) {
            tagr = 'wire:' + exp.replace('nasrv:','')
          } else if (startsWith(exp, 'afxsrv:')) {
            tagr = 'wire:' + exp.replace('afxsrv:','')
          } else {
            tagr = exp
          }
          return <TextField key={i} record={{tagr}} source='tagr' style={{overflowWrap: 'anywhere'}}/>
        })
      }
  </div>
  )
}

OredTagRegexpsField.defaultProps = { addLabel: true }

const FeedConversionsField = ({ record }) => {
  const { conversions } = record
  return (
    <div>
      {
        conversions.map((c, i) => {
          const conv = JSON.parse(c)
          const { feed_url, nickname } = conv
          return (
            <SimpleShowLayout record={conv}>
              <TextField key={nickname} record={nickname} source='nickname' label='Name'/>
              <TextField key={feed_url} record={feed_url} source='feed_url' label='URL' />
            </SimpleShowLayout>
          )
        })
      }
    </div>
  )
}
FeedConversionsField.defaultProps = { addLabel: true }

const S3PushField = ({ record }) => {
  const { s3push_parameters } = record.s3push_obj
  const { s3_provider, s3_url, accesskey } = s3push_parameters
  console.log('S3PushField', s3push_parameters)
  return (
    <div>
      {
        <SimpleShowLayout record={s3push_parameters}>
          { s3_provider ?
            <TextField key={s3_provider} record={s3_provider} source='s3_provider' label='S3-Provider' />
            : null
          }
          <TextField key={s3_url} record={s3_url} source='s3_url' label='S3-URL-Präfix' />
          <TextField key={accesskey} record={accesskey} source='accesskey' label='Access Key ID'/>
        </SimpleShowLayout>
      }
    </div>
  )
}
S3PushField.defaultProps = { addLabel: true }

const ApiKindField = ({ record }) => {
  const { api_kinds } = record
  return (
    <div>
      {
        api_kinds.map((api_kind, i) => {
          return <TextField key={i} record={{api_kind}} source='api_kind' />
        })
      }
    </div>
  )
}
ApiKindField.defaultProps = { addLabel: true }

const ApiIsActivatedField = ({ record }) => {
  const { feeds_activated } = record
  return (
    <div>
      {
        feeds_activated.map((is_activated, i) => {
          return <BooleanField key={i} record={{is_activated}} source='is_activated' />
        })
      }
    </div>
  )
}
ApiIsActivatedField.defaultProps = { addLabel: true }

const ApiFeedIdField = ({ record }) => {
  const { feed_ids } = record
  return (
    <div>
      {
        feed_ids.map((feed_id, i) => {
          return <TextField key={i} record={{feed_id}} source='feed_id' />
        })
      }
    </div>
  )
}
ApiFeedIdField.defaultProps = { addLabel: true }

const ValidFromField = ({ record }) => {
  const { valid_froms } = record
  const valid_from = valid_froms[record.id]
  return (
    <div>
      {
        <TextField record={{valid_from}} source='valid_from' />
      }
    </div>
  )
}
ValidFromField.defaultProps = { addLabel: true }

const styles = {
  valid_to: { color: 'red' }
}

const ValidToField = withStyles(styles)(({classes, record}) => {
  const { valid_tos } = record
  const valid_to = valid_tos[record.id]
  if (valid_to) {
    const valid_to_date = new Date(valid_to)
    valid_to_date.setHours(23)
    valid_to_date.setMinutes(59)
    const today = new Date()
    if (valid_to_date < today) {
      // Color indicator for expired feeds
      return (
        <TextField className={classes.valid_to} record={{valid_to}} source='valid_to' />
      )
    } else {
      return (
        <TextField record={{valid_to}} source='valid_to' />
      )
    }
  } else {
    return null
  }}
)
ValidToField.defaultProps = { addLabel: true }

const SalesDescriptionField = ({ record }) => {
  const { sales_description } = record
  const sales_descr = sales_description[record.id]
  return (
    <div>
      {
        <TextField record={{sales_descr}} source='sales_descr' />
      }
    </div>
  )
}
SalesDescriptionField.defaultProps = { addLabel: true }

const GraphQLUrlsField = ({ record }) => {
  const { graphql_endpoint_urls } = record
  return (
    <div>
      {
        graphql_endpoint_urls.map((gql_url, i) => {
          return <TextField key={i} record={{gql_url}} source='gql_url' />
        })
      }
    </div>
  )
}
GraphQLUrlsField.defaultProps = { addLabel: true }


const FeedProfileShow = ({ ...props }) => {
  const { record } = props

  return (
    <SimpleShowLayout {...props}>
        {
          record && record.query_ored_tag_regexps && record.query_ored_tag_regexps.length
          ? <OredTagRegexpsField source='profile_summary' label='Profil' />
          : null
        }
        {
          record && record.feed_url_obj && record.feed_url_obj.is_activated
          ? <TextField source='feed_url_obj.feed_url' label='JSON-Feed URL' />
          : (record.feed_url_obj ?
              <TextField source='feed_url_obj.defaultText' label='JSON-Feed URL' />
              : null)
        }
        {
          record && record.conversions
          ? <FeedConversionsField source='conversions' label='Conversions' />
          : null
        }
        {
          record && record.base_url_obj && record.base_url_obj.is_activated
          ? <TextField source='base_url_obj.base_url' label={'Base-URL für ' + record.base_url_obj.api_kind} />
          : (record.base_url_obj ? 
              <TextField source='base_url_obj.defaultText' label='Base-URL' />
              : null
          )
        }
        {
          record && record.graphql_endpoint_urls && record.graphql_endpoint_urls.length
          ? <GraphQLUrlsField source='graphql_endpoint_urls' label='GraphQL Endpunkt URL(s)' />
          : null
        }
        {
          record && record.s3push_obj && record.s3push_obj.is_activated ?
            <S3PushField source='s3push_obj.s3push_parameters' label='S3-Push' />
          : (record.s3push_obj ?
              <TextField source='s3push_obj.defaultText' label='S3-Push' />
              : null)
        }
    </SimpleShowLayout>
  )
}

class FeedOverview extends Component {

  constructor(props) {
    super(props)
    const { record } = props
    this.state = { aufschaltung: record }
  }

  onProfileEditFinished = ({complete_au, processUpdatingAufschaltung}) => {
    // Hook which is called whenever edit profile is finished
    this.onDisableButtons(processUpdatingAufschaltung)
    this.setState({ aufschaltung: complete_au })
  }

  onDisableButtons = (disable) => {
    // Hook which is called whenever the update request is finished
    const { onDisableButtons } = this.props
    onDisableButtons(disable)
  }

  render () {
    const { aufschaltung } = this.state
    let feeds = aufschaltung.feeds || []
    let profiles = aufschaltung.profiles || []

    let prof_summary = {}
    let valid_from_obj = {}
    let valid_to_obj = {}
    let sales_descr_obj = {}
    let tech_mat_obj = {}
    for (let fIndex in feeds) {
      let f = feeds[fIndex]
      if (f.for_internal_apikp_use_only) continue
      const nickname = profiles.filter(p => p.id === f.profile_id)[0].nickname
      // Collect valid_from/valid_to from feeds
      if (f.valid_from) {
        valid_from_obj[f.profile_id] = f.valid_from
      }
      if (f.valid_to) {
        valid_to_obj[f.profile_id] = f.valid_to
      }
      // Collect sales_description from feeds
      if (f.sales_description) {
        sales_descr_obj[f.profile_id] = f.sales_description
      }
      // Collect technical material numbers from data
      if (f.technical_material_numbers) {
        tech_mat_obj[f.profile_id] = f.technical_material_numbers
      } else {
        tech_mat_obj[f.profile_id] = []
      }

      // profile entry already existing?
      let profile = prof_summary && prof_summary[nickname] ? prof_summary[nickname] : {}
      let graphql_endpoint_urls = []
      if (f.graphql_endpoint_url) {
        graphql_endpoint_urls.push(f.graphql_endpoint_url)
      }
      const query_ored_tag_regexps = profiles.filter(p => p.id === f.profile_id)[0].query_ored_tag_regexps

      let feed_url_obj
      let base_url_obj
      let s3push_obj
      if (f.api_kind === 'jsonfeed') {
        feed_url_obj = {
          api_kind: f.api_kind,
          is_activated: f.is_activated,
          feed_url: f.feed_url,
          defaultText: 'JSON-Feed ist noch nicht eingerichtet.'
        }
      } else if (f.api_kind === 'wireq') {
        base_url_obj = {
          api_kind: f.api_kind,
          is_activated: f.is_activated,
          base_url: f.base_url,
          defaultText: 'wireq-Feed ist noch nicht eingerichtet.'
        }
      } else if (f.api_kind === 's3push') {
        s3push_obj = {
          api_kind: f.api_kind,
          is_activated: f.s3push_activated,
          s3push_parameters: f.s3push_parameters,
          defaultText: 'S3-Push ist noch nicht eingerichtet.'
        }
      } else if (f.api_id && f.api_id.includes('api-apigate')) {
        base_url_obj = {
          api_kind: f.api_kind,
          is_activated: f.is_activated,
          base_url: f.base_url,
          defaultText: f.api_kind + ' Feed ist noch nicht eingerichtet.'
        }
      } 

      prof_summary[nickname] = {
        id: f.profile_id,
        nickname: nickname,
        valid_froms: valid_from_obj,
        valid_tos: valid_to_obj,
        sales_description: sales_descr_obj,
        technical_materials: tech_mat_obj,
        feed_url_obj: profile.feed_url_obj ? profile.feed_url_obj : feed_url_obj,
        graphql_endpoint_urls: profile.graphql_endpoint_urls ?
          profile.graphql_endpoint_urls.concat(graphql_endpoint_urls) : graphql_endpoint_urls,
        s3push_obj: profile.s3push_obj ? profile.s3push_obj : s3push_obj,
        conversions: profile.conversions ? profile.conversions : f.conversions,
        base_url_obj: profile.base_url_obj ? profile.base_url_obj : base_url_obj,
        api_kinds: profile.api_kinds ? profile.api_kinds.concat([f.api_kind]) : [f.api_kind],
        feeds_activated: profile.feeds_activated ? profile.feeds_activated.concat([f.is_activated]) : [f.is_activated],
        feed_ids: profile.feed_ids ? profile.feed_ids.concat([f.id]) : [f.id],
        query_ored_tag_regexps: query_ored_tag_regexps
      }
    }

    const profile_data = {
        profiles: Object.values(prof_summary)
    }

    return (
        <ArrayField addLabel={false} record={profile_data} source='profiles'>
        <Datagrid rowClick='expand' expand={<FeedProfileShow />}>
          <TextField source='nickname' label='Profil' />
          <ApiKindField source='api_kinds' label='API' />
          <ApiIsActivatedField source='feeds_activated' label='Aktiviert?' />
          <ApiFeedIdField source='feed_ids' label='Feed-ID' />
          <ValidFromField source='valid_froms' label='Beginn' />
          <ValidToField source='valid_tos' label='Ende' />
          <SalesDescriptionField source='sales_description' label='Sales Description' />
          <MaterialEditField
            aufschaltung={aufschaltung}
            source='technical_materials'
            label='Technische Materialien'
            technical_materials={tech_mat_obj}
            onProfileEditFinished={this.onProfileEditFinished}
            onDisableButtons={this.onDisableButtons}
            buttonsDisabled={this.props.buttonsDisabled} />
          <ProfileEditorButton
            isCreate={false}
            aufschaltung={aufschaltung}
            onProfileEditFinished={this.onProfileEditFinished}
            onDisableButtons={this.onDisableButtons}
            buttonsDisabled={this.props.buttonsDisabled} />
          </Datagrid>
        </ArrayField>
      )
    }
}

class FeedTabOverview extends Component {
  constructor(props) {
    super(props)
    const { record } = props
    this.state = { aufschaltung: record }
    // state whether create/edit profile buttons are disabled during processing
    this.buttonsDisabled = false
  }

  onProfileEditFinished = ({complete_au, processUpdatingAufschaltung}) => {
    // Hook which is called whenever edit profile is finished
    this.buttonsDisabled = processUpdatingAufschaltung
    this.setState({aufschaltung: complete_au})
  }

  onDisableButtons = (disable) => {
    // Hook which is called whenever the update request is finished
    this.buttonsDisabled = disable
  }

  render () {
    const { aufschaltung } = this.state
    return (
      <Auth0Context.Consumer>
      {auth0_context => {
        const scopes = getUserScopes(auth0_context.access_token)
        this.buttonsDisabled = scopes.includes('edit:aufschaltung-profiles')
          ? this.buttonsDisabled : true
        return (
          <SimpleShowLayout {...this.props}>
              <ProfileEditorButton
                isCreate={true}
                aufschaltung={aufschaltung}
                onProfileEditFinished={this.onProfileEditFinished}
                onDisableButtons={this.onDisableButtons}
                buttonsDisabled={this.buttonsDisabled} />
              <FeedOverview {...this.props}
                buttonsDisabled={this.buttonsDisabled}
                onDisableButtons={this.onDisableButtons} />
          </SimpleShowLayout>
        )}
      }
      </Auth0Context.Consumer>
    )
  }
}

export { FeedTabOverview }
