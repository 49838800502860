import React from 'react'
import {
  Create,
  LongTextInput,
  SimpleForm,
  TextInput } from 'react-admin'

import { SelectStage } from './Aufschaltung'
import { EditToolbar } from './custom_components/CustomReactAdminComponent'

const redirect = (basePath, id, data) => `/Aufschaltung/${data.id}/show/3`

export const AufschaltungCreate = (props) => {
  return (
    <Create {...props}>
      <SimpleForm toolbar={<EditToolbar />} redirect={redirect}>
        <SelectStage label='Status' source='stage' value='DEMO' />
        <TextInput label='Organisation' source='orga_name' />
        {/*
        <TextInput label='SAP-Kunde' source='orga_number' />
        <BooleanInput label="Übertragung ins SAP-System" source="send_to_sap" />
        */}
        <LongTextInput label='Kommentar' source='orga_description' />
      </SimpleForm>
    </Create>
  )
}
