import React from 'react'
import { Link } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add'
import { withStyles } from '@material-ui/core/styles'
import { Button } from 'react-admin'

import { buttonStyles } from './styles'

const AddUserInviteButton = ({ classes, record }) => {
  return (
    <Button
      className={classes.button}
      variant='flat'
      component={Link}
      to={`/Invitation/create?aufschaltung_id=${record.auid}`}
      label='Benutzer einladen'
      title='Benutzer einladen'
    >
      <AddIcon />
    </Button>
  )
}

export default withStyles(buttonStyles)(AddUserInviteButton)
