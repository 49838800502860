import React from 'react'
import prettyBytes from 'pretty-bytes'

export const BytesField = (props) => {
  const { record } = props
  const { feeds } = record
  const feed = feeds.find(f => (
      f.api_kind === 's3push' && f.s3push_activated === true))
  const api_activated = feed ? feed.is_activated : false
  const s3push_activated = feed ? feed.s3push_activated : false

  const { statistics_overview } = record
  let statistics = JSON.parse(statistics_overview)
  let bytes = statistics_overview && statistics.bytes ? statistics.bytes : 0
  bytes = (s3push_activated && bytes === 0) || (!api_activated && bytes === 0) ?
    ' — ' : prettyBytes(bytes, {locale: 'de'})
  return (
    <div>
        <span>{bytes}</span>
    </div>
  )
}
BytesField.defaultProps = { addLabel: true }


export const StatisticBar = (props) => {
  const { record } = props
  const { statistics_overview } = record
  let activity = statistics_overview ? JSON.parse(statistics_overview).activity : 0
  return (
    <div className={'progress-bar val-' + activity}>
    {/*<div className={'progress-bar multicolor val-' + activity}>*/}
      <div className='progress-segment' />
      <div className='progress-segment' />
      <div className='progress-segment' />
      <div className='progress-segment' />
    </div>
  )
}
StatisticBar.defaultProps = { addLabel: true }
