import React, { Component } from 'react'
import PropTypes from 'prop-types'
import ContentCreate from '@material-ui/icons/Create'
import Done from '@material-ui/icons/Done'
import Close from '@material-ui/icons/Close'
import {
  Button,
  GET_ONE,
  TextField,
  UPDATE,
  withDataProvider, } from 'react-admin'

import { buttonStyles, divStyle } from './styles'

class MaterialEditField extends Component {
  constructor(props) {
    super(props)
    this.current_technical_material = this.getTechnicalMaterial(props)
    this.state = {
      is_editable: false,
      technical_materials: this.current_technical_material
    }
  }

  getTechnicalMaterial (props) {
    const { technical_materials, record } = props
    const id = record ? record.id : props.id
    let tech_mats = technical_materials[id]
    tech_mats = tech_mats ? tech_mats.join(',') : ''
    return tech_mats
  }

  componentWillReceiveProps(nextProps) {
    const { record } = nextProps
    this.current_technical_material = this.getTechnicalMaterial(record)
    this.setState({ technical_materials: this.current_technical_material })
  }

  onStartEditing = (e) => {
    e.stopPropagation()
    this.setState({ is_editable: true })
  }

  updateInputValue = (e) => {
    const re = /^[0-9,]*$/
    if (e.target.value === '' || re.test(e.target.value)) {
      this.setState({technical_materials: e.target.value})
    }
  }

  onEditingFinished = (e) => {
    e.stopPropagation()
    this.setState({ is_editable: false })

    const { aufschaltung, dataProvider, record } = this.props
    const { feed_ids } = record
    const { technical_materials } = this.state
    
    const feeds = feed_ids.map(f_id => {
      return {
        feed_id: f_id,
        technical_material_numbers: technical_materials.split(",")
      }
    })
    const auid = aufschaltung.auid
    const data = {
      id: auid,
      auid: auid,
      technical_material: feeds
    }

    // HACK: update cached aufschaltung
    let au_feeds = aufschaltung.feeds || []
    for (let fIndex in au_feeds) {
      let f = au_feeds[fIndex]
      for (let tmIndex in feeds) {
        let tf = feeds[tmIndex]
        if (tf.feed_id === f.id) {
          f.technical_material_numbers = tf.technical_material_numbers
        }
      }
    }

    // this.onDisableButtons(true)
    this.onProfileEditFinished(aufschaltung)

    dataProvider(UPDATE, 'TechnicalMaterial', { id: auid, data: data }, {
      undoable: true,
      onSuccess: {
        notification: { body: 'Profil wird aktualisiert', level: 'info' },
        callback: () => {
          dataProvider(GET_ONE, 'Aufschaltung', { id: auid }, {
            onSuccess: {
              notification: { body: 'Profilaktualisierung abgeschlossen', level: 'info' },
              callback: () => {
                this.onDisableButtons(false)
              }
            },
            onFailure: {
              notification: { body: 'Fehler bei Aktualisierung des Profils', level: 'warning' },
              callback: () => {
                this.onDisableButtons(false)
              }
            }
          })
        }
      },
      onFailure: {
        notification: { body: 'Fehler bei Aktualisierung des Profils', level: 'warning' },
        callback: () => {
          this.onDisableButtons(false)
        }
      }
    })
  }

  onProfileEditFinished = (aufschaltung) => {
    const { onProfileEditFinished } = this.props
    onProfileEditFinished({complete_au: aufschaltung, processUpdatingAufschaltung: true})
  }

  onDisableButtons = (disable) => {
    const { onDisableButtons } = this.props
    onDisableButtons(disable)
  }

  onCancel = (e) => {
    e.stopPropagation()
    this.setState({ is_editable: false, technical_materials: this.current_technical_material })
  }

  render () {
    const { is_editable, technical_materials } = this.state
    const { buttonsDisabled } = this.props

    return (
      <div>
      {
        is_editable ? (
          <div style={divStyle}>
            <input value={technical_materials}
              onClick={(e) => e.stopPropagation()}
              onChange={(e) => this.updateInputValue(e)}/>
            <Button
              style={buttonStyles}
              variant='flat'
              onClick={(e) => this.onEditingFinished(e)}
            >
              <Done />
            </Button>
            <Button
              style={buttonStyles}
              variant='flat'
              onClick={(e) => this.onCancel(e)}
            >
              <Close />
            </Button>
          </div>
          ) : (
            <div style={divStyle}>
            <TextField record={{technical_materials}} source='technical_materials' />
            <Button
              style={buttonStyles}
              variant='flat'
              onClick={(e) => this.onStartEditing(e)}
              disabled={buttonsDisabled}
          >
            <ContentCreate />
          </Button>
          </div>
          )
      }
      </div>
    )
  }
}

MaterialEditField.propTypes = {
  dataProvider: PropTypes.func.isRequired,
  record: PropTypes.object
}

export default withDataProvider(MaterialEditField)