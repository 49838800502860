import React, { Fragment } from 'react'
import {
  DateField,
  NumberField,
  TextField,
  ShowController,
  ShowView,
  SimpleShowLayout } from 'react-admin'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'

import { cardStyle } from './custom_components/CustomReactAdminComponent'
import { LinkToAufschaltung } from './custom_components/LinkToAufschaltung'
import { InvitationTitle, InviteUrlField } from './Invitation'


const InvitationOverview = (props) => {
  const { record } = props
  const overviewTitle = (record && (record.orga_contact_person || record.email)) || ''
  return (
    <Card key={record.id} style={cardStyle}>
      <CardHeader
        title={<span>Einladung: {overviewTitle}</span>}
        subheader={<TextField record={record} source='orga_name' addLabel={true} />}
      />
      <CardContent>
        <SimpleShowLayout {...props}>
          <LinkToAufschaltung label='Aufschaltung' />
          <TextField source='orga_name' label='Organisation'/>
          <TextField source='orga_contact_person' label='Kontaktperson' />
          <TextField source='email' label='Email' />
          <TextField source='aufschaltung_id' label='Aufschaltung' />
          <NumberField source='max_user_nb' label='Max. Anzahl der User' />
          <DateField source='expires_at' label='Gültig bis' />
          <DateField source='created_at' label='Erstellt am' />
          {/*<UrlField source='invite_url' label='Einladungs-URL' style={{ width: 200 }} />*/}
          <InviteUrlField label='Einladungs-URL' />
        </SimpleShowLayout>
      </CardContent>
    </Card>
  )
}

export const InvitationShow = (props) => {
  return (
    <ShowController {...props} title={<InvitationTitle />}>{controllerProps =>
      <ShowView {...props} {...controllerProps} >
        <Fragment>
          <InvitationOverview {...controllerProps} />
        </Fragment>
      </ShowView>
    }
    </ShowController>
  )
}
InvitationShow.defaultProps = { addLabel: true }
