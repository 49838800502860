import React, {Component, Fragment} from 'react'
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  NumberField,
  ShowController,
  ShowView,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField } from 'react-admin'

import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardHeader from '@material-ui/core/CardHeader'

import AddUserInviteButton from './custom_components/AddUserInviteButton'
import SelectTimeinterval from './custom_components/SelectTimeinterval'

import { AufschaltungTitle } from './Aufschaltung'
import { UserAvatarField } from './AvatarFields'
import { ApiSummaryField } from './ApiFields'
import { cardStyle } from './custom_components/CustomReactAdminComponent'
import { StatisticBar } from './custom_components/StatisticBar'
import { FeedTabOverview } from './FeedFields'
import { ProfileSummaryField, ProfileStatistics } from './ProfileFields'
import { IsMainUserField } from './UserFields'


const AufschaltungOverview = ({ record }) => {
  const overviewTitle = (record && record.main_user && record.main_user.email) || ''
  return (
    <Card key={record.id} style={cardStyle}>
      <CardHeader
        avatar={<UserAvatarField record={record} size={80} />}
        title={<span>{overviewTitle} (Hauptbenutzer)</span>}
        subheader={<TextField record={record} source='orga_name' addLabel={true} />}
      />
      <CardContent>
        <Datagrid ids={[record.id]} data={{[record.id]: record}} currentSort={{}}>
          <TextField record={record} source='stage' addLabel={true} label='Status' />
          <StatisticBar label='Aktivität' sortBy='statistics_overview.activity' />
          <ProfileSummaryField record={record} label='Profile' />
          <ApiSummaryField record={record} filter={{is_activated: true, for_internal_apikp_use_only: null}} label='Aktive APIs' />
        </Datagrid>
      </CardContent>
    </Card>
  )
}

class AufschaltungShow extends Component {
  state = {
    timeinterval: 'last_24h'
  }

  onTimeIntervalChanged = event => {
    this.setState({timeinterval: event.target.value})
  }

  render () {
    const { timeinterval } = this.state
    return (
      <ShowController {...this.props} >{controllerProps =>
        <ShowView {...this.props} {...controllerProps} title={<AufschaltungTitle />} >
    
          <Fragment>
            <AufschaltungOverview {...controllerProps} />
    
            <TabbedShowLayout {...controllerProps} >
    
              {/*
              <Tab label='Organisation'>
              </Tab>
              */}
              <Tab label='Details'>
                <SimpleShowLayout className='tab-with-two-columns-left'>
                  <TextField source='nickname' label='Aufschaltungs-Name' />

                  <TextField source='auid' label='Aufschaltungs-ID' />
                  <TextField source='orga_number' label='SAP-Kunde' />
                  <BooleanField source="send_to_sap" label='Übertragung ins SAP-System' />
                </SimpleShowLayout>

                <SimpleShowLayout>
                  <TextField source='orga_description' label='Kommentar' style={{whiteSpace: 'pre-line'}} />
                </SimpleShowLayout>
                {/*<TextField source='obfuscation_prefix' />*/}
                {/*<RichTextField label='Logfiles' />*/}

              </Tab>
    
              <Tab label='Statistiken'>
                <SelectTimeinterval
                  {...this.props}
                  timeinterval={timeinterval}
                  onTimeIntervalChanged={this.onTimeIntervalChanged}
                />
                <ProfileStatistics {...this.props} timeinterval={timeinterval} />
              </Tab>
    
              <Tab label='Benutzer'>
                <AddUserInviteButton />
                <ArrayField addLabel={false} source='users'>
                  <Datagrid>
                    <UserAvatarField source='picture' label='' />
                    <TextField source='email' label='E-Mail' />
                    <BooleanField source='email_verified' label='E-Mail verifiziert?' />
                    <TextField source='name' label='Name' />
                    <IsMainUserField aufschaltung={controllerProps.record} label='Hauptbenutzer?' />
                    <DateField source='last_login' label='Letzter Login' showTime locales='de' />
                    <NumberField source='logins_count' label='Anzahl der Logins' />
                    <DateField source='created_at' label='Erstellt am' showTime locales='de' />
                  </Datagrid>
                </ArrayField>
              </Tab>
    
              <Tab label='Feeds'>
                <FeedTabOverview {...this.props} />
              </Tab>
    
            </TabbedShowLayout>
          </Fragment>
        </ShowView>
      }
      </ShowController>
    )
  }
}
export { AufschaltungShow }