import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'

const styles = theme => ({
  root: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  formControl: {
    float: 'right',
    justifyContent: 'flex-end',
    margin: theme.spacing.unit,
    minWidth: 120,
  },
  selectEmpty: {
    marginTop: theme.spacing.unit * 2,
  },
})

class SelectTimeinterval extends Component {
  //state = {
  //  timeinterval: 'this_month'
  //}

  //handleChange = event => {
  //  this.setState({ timeinterval: event.target.value })
  //}
  
  createMenu = (statistics) => {
    let periods = statistics.map(stat => (JSON.parse(stat).timeinterval))
    const timeintervalMapping = {
      'last_24h': 'Letzte 24 Stunden',
      'this_month': 'Aktueller Monat',
      'last_30d': 'Letzte 30 Tage',
      'last_month': 'Letzter Monat'
    }

    // Remove duplicates
    periods = Array.from(new Set([...periods, ...Object.keys(timeintervalMapping)]))
    // Sorting
    periods.sort()
    periods = periods.map(ti => (
      {id: ti, label: timeintervalMapping[ti] ? timeintervalMapping[ti] : ti}
    ))

    return periods.map(entry => <MenuItem value={entry.id}>{entry.label}</MenuItem>)
  }

  render () {
    const { classes, record, timeinterval, onTimeIntervalChanged } = this.props
    const { statistics } = record
    return (
      <FormControl className={classes.formControl}>
        <InputLabel shrink>Zeitraum</InputLabel>
        <Select
          value={timeinterval}
          onChange={onTimeIntervalChanged}
          name='timeinterval'
          className={classes.selectEmpty}
        >
          {this.createMenu(statistics)}
        </Select>
      </FormControl>
    )
  }
}

SelectTimeinterval.propTypes = {
  classes: PropTypes.object.isRequired,
}

export default withStyles(styles)(SelectTimeinterval)