export const buttonStyles = {
  button: {
    marginTop: '1em',
    display: 'flex',
    float: 'right',
    justifyContent: 'flex-end'
  }
}

export const divStyle = {
  display: 'flex',
  alignItems: 'center'
}
