import React from 'react'
import {
  Datagrid,
  DateField,
  List,
  NumberField,
  Pagination,
  TextField } from 'react-admin'

import { InvitationActions } from './Invitation'

const InvitationPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 300, 400, 500]} {...props} />
)

export const InvitationList = (props) => {
  return (
    <List {...props} actions={<InvitationActions />} title='Einladungen'
      sort={{ field: 'created_at', order: 'DESC' }}
      pagination={<InvitationPagination />}
      perPage={100}>
      <Datagrid rowClick='show'>
        <TextField source='orga_name' label='Organisation' />
        <TextField source='orga_contact_person' label='Kontaktperson' />
        <TextField source='email' label='E-Mail' />
        <TextField source='aufschaltung_id' label='Aufschaltung' />
        <NumberField source='max_user_nb' label='Max. Anzahl der User' />
        <DateField source='expires_at' label='Gültig bis' locales='de' />
        <DateField source='created_at' label='Erstellt am' locales='de' />
        {/*<TextField source='invite_url' label='Invitations-URL' />*/}
      </Datagrid>
    </List>
  )
}
