import React from 'react'
import { Link } from 'react-router-dom'
import { Button, } from 'react-admin'

import ArrowForward from '@material-ui/icons/ArrowForward'


const LinkToAufschaltung = ({ record }) => {
  const profile_nicknames = record && record.profile_nicknames ?
                            record.profile_nicknames : []
  return (
    <Button
      style={{
        display: 'flex',
        float: 'right',
        paddingRight: '0em',
        justifyContent: 'flex-end'}}
      addLabel={true}
      variant='flat'
      component={Link}
      to={`/Aufschaltung/${record.aufschaltung_id}/show`}
      label='Zur Aufschaltung'
      title={`Profile: ${profile_nicknames.join(' | ')}`}>
      <ArrowForward />
    </Button>
  )
}

export { LinkToAufschaltung }
