import React from 'react'

import { CardActions, RefreshButton } from 'react-admin'

import ContentCopy from '@material-ui/icons/ContentCopy'
import IconButton from '@material-ui/core/IconButton'
import TextField from '@material-ui/core/TextField'
import Tooltip from '@material-ui/core/Tooltip'

export const InvitationTitle = ({ record }) => {
    let title = (record && record.orga_name && record.orga_contact_person) || ''
    title = title ? `«${title}»` : ''
    return <span>Einladung {title}</span>
}

export const InvitationActions = ({
    bulkActions,
    basePath,
    //currentSort,
    displayedFilters,
    //exporter,
    filters,
    filterValues,
    onUnselectItems,
    resource,
    selectedIds,
    showFilter,
    //total
}) => (
    <CardActions>
        {bulkActions && React.cloneElement(bulkActions, {
            basePath,
            filterValues,
            resource,
            selectedIds,
            onUnselectItems,
        })}
        {filters && React.cloneElement(filters, {
            resource,
            showFilter,
            displayedFilters,
            filterValues,
            context: 'button',
        }) }
        <RefreshButton />
    </CardActions>
)

export const InviteUrlField = (props) => {
  const { record } = props
  return (
    <div>
      <TextField
        id='invite_url'
        defaultValue={record.invite_url}
        InputProps={{
            readOnly: true
        }}
        style={{ width: 500 }}
      />
      <Tooltip title='Kopieren'>
        <IconButton
          onClick={(/*event*/) => {
            const textField = document.getElementById('invite_url')
            textField.select()
            document.execCommand('copy')
            //event.target.focus()
          }}
        >
          <ContentCopy />
        </IconButton>
      </Tooltip>
    </div>
  )
}
InviteUrlField.defaultProps = { addLabel: true }
