import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import jwt_decode from 'jwt-decode'

const ATOKEN_KEY = process.env.REACT_APP_AUTH0_ATOKEN_KEY

class AtokenProvider extends Component {

  constructor (props) {
    super(props)

    const {store, id_token_payload, access_token, signed_in} = props

    this.atoken = this.extractAtoken({id_token_payload, access_token, signed_in})
    //console.log('found atoken_from_id_token', this.atoken)
    if (store) { store.atoken = this.atoken }  // share with apollo link
  }

  extractAtoken ({id_token_payload, access_token, signed_in}) {
    if (id_token_payload && signed_in && id_token_payload[ATOKEN_KEY]) {   // try to extract atoken from auth0provider's idtoken
      const atoken = id_token_payload[ATOKEN_KEY]
      //console.log('found atoken_from_id_token', atoken)
      return atoken

    } else if (access_token && signed_in) {    // try to use access_token as atoken, if it is a jwt
      try {
        jwt_decode(access_token)
        const atoken = access_token
        //console.log('using access_token as atoken', atoken)
        return atoken
      } catch(e) {
        console.log('access token is not a valid atoken')
        return null
      }
    }
    console.log('no atoken found')
    return null
  }

  handleAtoken ({ match, history }) {
    const { redirect_path, store } = this.props
    const atoken = match.params.atoken

    if (atoken) {
      //console.log(`got atoken ${atoken}`)

      this.atoken = atoken
      if (store) { store.atoken = atoken }  
      // localStorage.setItem('atoken', atoken)
      if (redirect_path) {
        history.replace(redirect_path)
      }
    }
  }

  render () {
    const { children } = this.props
    return (
      <Switch>
        <Route path='/atoken/:atoken' render={args => { // accept atoken from url
          this.handleAtoken(args)
          return null
        }} />
        <Route render={() => children } />
      </Switch>
    )
  }
}

export { AtokenProvider }
