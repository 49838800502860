import React from 'react'
import _ from 'lodash'
import prettyBytes from 'pretty-bytes'

import {
  ArrayField,
  Datagrid,
  NumberField,
  TextField } from 'react-admin'

const ProfileSummaryField = ({ record, filter }) => {
  if (!record || !record.profiles) { return null }
  const items = filter ? _.filter(record.profiles, filter) : record.profiles
  return (
    <div>
      { items.length > 0 ? items.map((item, i) => (
        <TextField key={i} record={item} source='nickname' />))
        : (<div> — </div>)
      }
    </div>
  )
}

ProfileSummaryField.defaultProps = { addLabel: true, label: 'Profile' }

const ProfileStatistics = (props) => {
  const { record, timeinterval } = props
  const { profiles, statistics } = record
  let entries = []
  // put together profile statistics
  for (let pIndex in profiles) {
    let p = profiles[pIndex]
    let entry = {
      s3push_count: ' — ',
      jsonfeed_count_200: ' — ',
      jsonfeed_count_304: ' — ',
      bytes: ' — ',
      wireq_count: ' — ',
      wireq_bytes: ' — ',
      annotation: ' — ',
      preview: ' — ',
      imagewriter: ' — '
    }
    entry.profile_nickname = p.nickname
    for (let sIndex in statistics) {
      let s = statistics[sIndex]
      s = JSON.parse(s)
      if (s.timeinterval !== timeinterval || s.profileid !== p.id) continue

      if (s.api === 's3push' && s.measure === 'action-count' && s.unit === 'count') {
        entry.s3push_count = s.value
      } else if (s.api === 'jsonfeed' && s.status === '200' && s.measure === 'request-count' && s.unit === 'count') {
        entry.jsonfeed_count_200 = s.value
      } else if (s.api === 'jsonfeed' && s.status === '304' && s.measure === 'request-count' && s.unit === 'count') {
        entry.jsonfeed_count_304 = s.value
      } else if (s.api === 'jsonfeed' && s.measure === 'request-bytes' && s.unit === 'bytes') {
        entry.bytes = prettyBytes(s.value, {locale: 'de'})
      } else if (s.api === 'wireq' && s.measure === 'request-count' && s.unit === 'count') {
        entry.wireq_count = s.value
      } else if (s.api === 'wireq' && s.measure === 'request-bytes' && s.unit === 'bytes') {
        entry.wireq_bytes = prettyBytes(s.value, {locale: 'de'})
      } else if (s.api === 'graphqlannotation') {
        entry.annotation = s.value
      } else if (s.api === 'graphqlapikp') {
        entry.preview = s.value
      } else if (s.api === 'graphqlimagewriter') {
        entry.imagewriter = s.value
      }
      entry.timeinterval = s.timeinterval
    }
    entries.push(entry)
  }
  const profile_data = {
    profiles: entries
  }

  return (
    <ArrayField addLabel={false} record={profile_data} source='profiles'>
      <Datagrid>
        <TextField source='profile_nickname' label='Profil' />
        <NumberField source='s3push_count' label='S3-Push #' />
        <NumberField source='jsonfeed_count_200' label='JSON-Feed #200' />
        <NumberField source='jsonfeed_count_304' label='JSON-Feed #304' />
        <TextField source='bytes' label='JSON-Feed bytes' />
        <TextField source='wireq_count' label='WireQ #' />
        <TextField source='wireq_bytes' label='WireQ bytes' />
        <TextField source='annotation' label='Annotation' />
        <TextField source='preview' label='Preview' />
        <TextField source='imagewriter' label='Imagewriter' />
      </Datagrid>
    </ArrayField>
  )
}

export { ProfileSummaryField, ProfileStatistics }