import React from 'react'
import { Link } from 'react-router-dom'
import CancelIcon from '@material-ui/icons/Cancel'
import { withStyles } from '@material-ui/core/styles'
import { Button } from 'react-admin'

const styles = {
  button: {
    position: 'relative',
  },
  iconPaddingStyle: {
    marginRight: '0.5em',
  }
}


const CancelButton = (props) => {
  const { basePath, classes, variant } = props
  return (
    <Button
      className={classes.button}
      variant={variant}
      color='primary'
      component={Link}
      to={`${basePath}`}
      label='Abbrechen'
      title='Abbrechen'
      size='large'
    >
      <CancelIcon />
    </Button>
  )
}

export default withStyles(styles)(CancelButton)
