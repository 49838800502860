import React from 'react'
import {
  Datagrid,
  downloadCSV,
  Filter,
  List,
  Pagination,
  SearchInput,
  TextField } from 'react-admin'

import { unparse as convertToCSV } from 'papaparse/papaparse.min';

import { getUserScopes } from 'au_processor'

import { Auth0Context } from './authentication/Auth0Provider'
import { ApiSummaryField } from './ApiFields'
import { UserAvatarField } from './AvatarFields'
import { ProfileSummaryField } from './ProfileFields'
import {
  MainUserSummaryField,
  MainUserLastLogin,
  MainUserLoginsCount,
  MainUserCreatedAt } from './UserFields'
import {
  AufschaltungActions,
  AufschaltungCreated,
  AufschaltungSendToSapField,
  CheckStage } from './Aufschaltung'
import { BytesField, StatisticBar } from './custom_components/StatisticBar'

const AufschaltungPagination = (props) => (
  <Pagination rowsPerPageOptions={[50, 100, 200, 300, 400, 500, 1000]} {...props} />
)

const AufschaltungFilter = (props) => (
  <Auth0Context.Consumer>
  {auth0_context => {
    const scopes = getUserScopes(auth0_context.access_token)
    return (
      <Filter {...props}>
        <CheckStage label='Status' source='stage' />
        <SearchInput label='Organisation' source='orga_name' />
        {
          scopes.includes('filter:aufschaltung-auid')
          ? <SearchInput label='auid' source='auid' /> : null
        }
      </Filter>
    )
  }
  }
  </Auth0Context.Consumer>
)

const rowStyle = (record, index) => ({
  verticalAlign: 'top',
  whiteSpace: 'pre-wrap'
})

const exporter = aufschaltungen => {
  const aus_export = aufschaltungen.map(au => {
    const {
      auid,
      orga_name,
      orga_number,
      stage,
      created,
      feeds,
      profiles } = au
    let au_for_export = {}
    au_for_export.organisation = orga_name
    au_for_export.sap_nummer = orga_number
    au_for_export.status = stage
    au_for_export.auid = auid
    au_for_export.erstellt_am = created
    au_for_export.profile = profiles ? profiles.map(p => p.nickname) : []
    au_for_export.profile = au_for_export.profile ? au_for_export.profile.join(',') : ''
    au_for_export.aktivierte_apis = feeds ? feeds.filter(f => f.api_kind !== 'graphqlapikp' && (f.is_activated === true || f.s3push_activated === true)) : []
    // Only unique apis
    au_for_export.aktivierte_apis = au_for_export.aktivierte_apis.map(f => f.api_kind)
    au_for_export.aktivierte_apis = [...new Set(au_for_export.aktivierte_apis)]
    au_for_export.aktivierte_apis = au_for_export.aktivierte_apis.length !== 0 ? au_for_export.aktivierte_apis.join(',') : ''
    au_for_export.api_abruf = au.statistics ? (au.statistics.length === 0 ? 'keiner' : 'regelmaessig') : 'keiner'
    return au_for_export;
  });
  const csv = convertToCSV({
      data: aus_export,
      fields: ['organisation', 'sap_nummer', 'status', 'api_abruf', 'aktivierte_apis', 'erstellt_am', 'auid', 'profile']  // fields to export
  });
  downloadCSV(csv, 'aufschaltungen'); // download as 'aufschaltungen.csv` file
}

export const AufschaltungList = props => {
  const { search } = props.location
  const filters = search && search.split('&')
  const sortOrder = filters && filters[1].split('=')[1]
  return (
    <Auth0Context.Consumer>
    {auth0_context => {
      const scopes = getUserScopes(auth0_context.access_token)
      const defaultFilter = scopes.includes('view:prod-stage') ?
        {orga_name: '', stage: ['PROD']} :
        {orga_name: '', stage: ['PROD', 'PREVIEW', 'DEMO']}
      return (
        <List {...props} filters={<AufschaltungFilter />}
          title='Aufschaltungen'
          filterDefaultValues={defaultFilter}
          exporter={exporter}
          actions={<AufschaltungActions />}
          sort={{ field: 'main_user.created_at', order: 'DESC' }}
          pagination={<AufschaltungPagination />}
          perPage={50}
          bulkActionButtons={false}>
          <Datagrid rowClick='show' rowStyle={rowStyle}>
            {/*<OrganisationAvatarField />*/}
            <TextField source='orga_name' label='Organisation' />
            <UserAvatarField />
            <MainUserSummaryField label='Hauptbenutzer (*)' sortBy='main_user.email' />
            <TextField source='stage' label='Status' />
            <AufschaltungSendToSapField label='SAP-System' />
            <StatisticBar label='Aktivität' sortBy='statistics_overview.activity' />
            <BytesField sortBy='statistics_overview.bytes' label='Bytes (30 Tage)' />
            <ProfileSummaryField label='Profile' />
            <ApiSummaryField filter={{is_activated: true, for_internal_apikp_use_only: null}} label='Aktive APIs' sortBy='api_kind' />
            <AufschaltungCreated label='Erstellt am' source='created' sortOrder={sortOrder} locales='de' />
            <MainUserLastLogin label='Letzter Login' sortOrder={sortOrder} sortBy='main_user.last_login' />
            <MainUserLoginsCount label='Anzahl der Logins' sortOrder={sortOrder} sortBy='main_user.logins_count' />
            <MainUserCreatedAt label='Benutzer erstellt am' sortOrder={sortOrder} sortBy='main_user.created_at' />
          </Datagrid>
        </List>
      )}
    }
    </Auth0Context.Consumer>
  )
}
