import React from 'react'

import {
  SaveButton,
  Toolbar } from 'react-admin'

import CancelButton from './CancelButton'

export const cardStyle = {
  marginBottom: '1em',
  boxShadow: 'none',
  border: 0
}

export const EditToolbar = props => (
  <Toolbar {...props} >
    <SaveButton variant='flat' /*redirect='show'*/ />
    <CancelButton variant='flat' />
  </Toolbar>
)
