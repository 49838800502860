import React, { Component } from 'react'
import { Switch, Route } from 'react-router-dom'

import auth0 from 'auth0-js'
import jwt_decode from 'jwt-decode'
import url from 'url'

import { Loading } from '../custom_components/Loading'

const CALLBACK_PATH = '/callback'

const Auth0Context = React.createContext()

class Auth0Provider extends Component {
  // auth0-hosted login dialog
  // utilizes auth-js
  // handles callback routes and /login /logout routes,
  // handles invite tokens (via auth0 rule)
  // passes auth0 id token payload to auth0COntext consumers (like AtokenFromAuth0Provider)

  constructor (props) {
    super(props)

    const { logout_path } = props
    const current_url = window.location.href

    this.base_url = url.resolve(current_url, '/')
    this.logout_url = url.resolve(current_url, logout_path || '/')

    this.auth0 = new auth0.WebAuth({
      domain: process.env.REACT_APP_AUTH0_DOMAIN,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID,
      redirectUri: url.resolve(current_url, CALLBACK_PATH),
      audience: process.env.REACT_APP_AUTH0_AUDIENCE,
      responseType: 'token id_token',
      scope: 'openid',
      leeway: 60
    })
  }

  login ({ivtoken}) {
    this.auth0.authorize({
      addopts: JSON.stringify(this.make_addopts()),    // pass config to customized hosted login page
      ivtoken: ivtoken || ''
    })
  }

  handleAuthentication ({location, history}) {
    return new Promise((resolve, reject) => {
      if (/access_token|id_token|error/.test(location.hash)) {
        this.auth0.parseHash((err, authResult) => {
          if (authResult && authResult.accessToken && authResult.idToken) {
            resolve(authResult)
          } else {
            console.log(err)
            reject(err)
          }
        })
      } else {
        reject()
      }
    }).then(authResult => {
      this.setSession({authResult})
    }).catch(err => {
      this.deleteSession()
    })
  }

  logout () {
    this.deleteSession()
    this.auth0.logout({
      returnTo: this.logout_url,
      clientID: process.env.REACT_APP_AUTH0_CLIENT_ID
    })
  }

  setSession ({authResult}) {
    if (authResult) {
      let expiresAt = JSON.stringify((authResult.expiresIn * 1000) + new Date().getTime())
      localStorage.setItem('access_token', authResult.accessToken)
      localStorage.setItem('id_token', authResult.idToken)
      localStorage.setItem('expires_at', expiresAt)
    }
  }

  deleteSession () {
    localStorage.removeItem('access_token')
    localStorage.removeItem('id_token')
    localStorage.removeItem('expires_at')
  }

  isAuthenticated () {
    let expiresAt = JSON.parse(localStorage.getItem('expires_at'))
    return new Date().getTime() < expiresAt
  }

  make_addopts () {
    var loginHint= null
    return {
      language: 'de',
      languageDictionary: {
        title: this.props.title || '',
        signUpTerms: 'I agree to the <a href="' +
          url.resolve(this.base_url, '/terms.html') + '" target="_new">terms of service</a> and <a href="'+
          url.resolve(this.base_url, '/privacy.html') + '" target="_new">privacy policy</a>.'
      },
      mustAcceptTerms: true,
      prefill: loginHint ? { email: loginHint, username: loginHint } : null,
      closable: false,
      theme: {
        primaryColor: '#868686',  // '#627985',
        logo: url.resolve(this.base_url, '/img/dpa-infocom-logo-sw.png'),
        authButtons: {
          'dpaid-oauth2-staging': {
            displayName: 'dpa-id'
          }
        }
      },
      // socialButtonStyle: 'big',
      rememberLastLogin: true
    }

    // works for signup only:
    //if (ivtoken) {
    //  addopts.additionalSignUpFields = [{
    //    name: "ivtoken",
    //    prefill: ivtoken,
    //    placeholder: "_",
    //  }]
    //  addopts.initialScreen = "signUp"
    //  addopts.signUp = true
    //  addopts.allowLogin = true
    //}
  }

  render () {
    const { children, home_path = '/home' } = this.props

    const signed_in = this.isAuthenticated()
    const id_token = localStorage.getItem('id_token')
    const access_token = localStorage.getItem('access_token')
    const id_token_payload = id_token && jwt_decode(id_token)
    const logout = this.logout.bind(this)

    return (
      <Switch> 
        <Route path='/login' render={() => {
          this.login({})
          return <Loading />
        }} />

        <Route path='/logout' render={() => {
          this.logout()
          return <Loading />
        }} />

        <Route path={CALLBACK_PATH} render={({location, history}) => {
          this.handleAuthentication({location, history}).then(() => {
            history.replace(home_path)
          }).catch(() => {
            history.replace('/')
          })
          return <Loading />
        }} />

        <Route path='/ivtoken/:ivtoken' render={({match, location, history}) => {
          this.login({ivtoken: match.params.ivtoken})
          return <Loading />
        }} />

        <Route render={({history}) => {
          // default route, provide auth0 context and render children

          if (!signed_in) {
            history.replace('/login')
            return <Loading />
          } else {
            return (
              <Auth0Context.Provider value={{id_token_payload, signed_in, access_token, logout}}>
                { children }
              </Auth0Context.Provider>
            )
          }
        }} />
      </Switch>
    )
  }
}

export { Auth0Provider, Auth0Context }
